<template>
  <type-list :type="type" :acceptType="acceptType"></type-list>
</template>
<script>
import typeList from './TypeList.vue';
export default {
  props: {},
  data() {
    return {
      acceptType: '.ppt,.pptx,.doc,.docx,.pdf,.zip,.xlsx,.txt,.csv,.xls',
      type: 'info',
    };
  },
  created() {},
  mounted() {},
  methods: {},
  components: { typeList },
};
</script>
<style lang="scss" scoped></style>
