<template>
  <div class="files_contents">
    <el-form :model="form" ref="coursesForm" label-position="right" label-width="150px" class="addForm">
      <div class="" v-if="is_admin_middleground === 'true'">
        <el-select v-model="form.options" class="search-type search-type_first" @change="choseOptions">
          <el-option v-for="item in nameList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-input v-model="form.name" placeholder="请输入查询条件" class="search-input"></el-input>
      </div>
      <div class="" v-else>
        <span class="type_text">名称</span>
        <el-input v-model="form.name" placeholder="请输入查询条件" class="search-input"></el-input>
      </div>
      <div class="">
        <span class="type_text">创建时间</span>
        <el-date-picker
          class="vertical-end"
          style="width: 269px"
          @change="timeChange"
          v-model="form.change_time"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="tool_btn">
        <el-button type="primary" class="search-btn confirm" @click="searchSubmit" style="margin-left: 20px">查询</el-button>
        <el-button class="search-btn reset" @click="resetFun">重置</el-button>
      </div>
      <div class="yellow_btn">
        <el-button class="yellow" @click="openUploadVideo">上传{{ fileName }}</el-button>
      </div>
    </el-form>
    <div class="total">
      <div class="left">{{ fileName }}</div>
      <div class="right">
        <el-button type="text" class="total-right vertical-center" @click="allDel">
          <img src="~assets/images/index/del.png" alt="" style="vertical-align: bottom" class="del" />
          <img src="~assets/images/index/del-hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
          <span style="margin-left: 8px">批量删除</span>
        </el-button>
        <el-button type="text" class="total-right vertical-center" @click="allDown">
          <img src="~assets/images/index/down_blue.png" alt="" style="vertical-align: bottom" class="del" />
          <img src="~assets/images/index/down_hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
          <span style="margin-left: 8px">批量下载</span>
        </el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      class="table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400' }"
      border
    >
      <el-table-column type="selection" label="全选" width="90" align="center"> </el-table-column>
      <el-table-column label="名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div class="el-name file_name">
            <!-- <el-image :src="scope.row.path" :preview-src-list="srcList(scope.row)"> </el-image>
            <p>{{ scope.row.name }}</p> -->
            <div class="el-name file_name" @click="videoPlay(scope.row)">
              <img src="~assets/images/index/play_video.png" alt="" v-if="scope.row.file_model_type === 'video'" />
              <el-image v-if="scope.row.file_model_type === 'image'" :src="scope.row.path" :preview-src-list="srcList(scope.row)"> </el-image>
              <img src="~assets/images/index/word.png" alt="" v-if="scope.row.file_model_type === 'info'" />
              <p>{{ scope.row.name }}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="大小" width="90px">
        <template slot-scope="scope">
          <div class="el-name">
            {{
              scope.row ? ((scope.row.size / 1024 / 1024).toFixed(2) > 1000 ? (scope.row.size / 1024 / 1024 / 1024).toFixed(2) + 'GB' : (scope.row.size / 1024 / 1024).toFixed(2) + 'MB') : ''
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建人" width="150px">
        <template slot-scope="scope">
          <div class="el-name el-nickname">{{ scope.row.user ? scope.row.user.nickname : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="170px">
        <template slot-scope="scope">
          <div class="el-name">{{ scope.row.created_at }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="110">
        <template slot-scope="scope">
          <el-button type="text" size="small" class="table-edit" @click="updata(scope.row)">编辑</el-button>
          <span style="color: #d0d6de; margin: 0 8px">|</span>
          <el-dropdown @command="handleCommand" trigger="click">
            <span class="table-edit table-span"> 更多 </span>
            <el-dropdown-menu slot="dropdown" class="drop">
              <el-dropdown-item :command="['share', scope.row]"><i class="el-icon-check" style="opacity: 0"></i>分享</el-dropdown-item>
              <el-dropdown-item :command="['del', scope.row]"><i class="el-icon-check" style="opacity: 0"></i>删除</el-dropdown-item>
              <el-dropdown-item :command="['down', scope.row]"><i class="el-icon-check" style="opacity: 0"></i>下载</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="page"
      background
      v-if="listData.length !== 0 && total > 10"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[20, 50, 100]"
      :page-size="pageSize"
      layout="prev, pager, next,total, jumper"
      :total="total"
    >
    </el-pagination>
    <!--编辑--->
    <el-dialog
      class="dialog"
      title="编辑名称"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
      top="calc( 50vh - 150px )"
      width="540px"
      :destroy-on-close="true"
      @close="close('from')"
      :close-on-click-modal="false"
    >
      <!--  表单-->
      <el-form label-position="left" label-width="60px" class="form" ref="from" :rules="rules" :model="editForm">
        <el-form-item label="名称" prop="videoname" class="is-required">
          <el-input v-model.trim="editForm.name" placeholder="请输入名称" maxlength="30"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="close('from')">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="preservation('from')">保存</el-button>
      </span>
    </el-dialog>
    <!--    删除弹框-->
    <el-dialog
      class="delDiolog"
      :close-on-click-modal="false"
      :visible.sync="isdelDialogVisible"
      top="calc( 50vh - 150px )"
      icon="el-icon-warning"
      :destroy-on-close="true"
      width="360px"
      center
    >
      <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
      <span>你确定要删除该数据么？</span>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="isdelDialogVisible = false">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="putTagStatus">确 定</el-button>
      </span>
    </el-dialog>
    <!--分享--->
    <el-dialog
      class="dialog share_dialog"
      :close-on-click-modal="false"
      title="分享"
      :visible.sync="dialogShare"
      v-if="dialogShare"
      top="calc( 50vh - 150px )"
      width="540px"
      :destroy-on-close="true"
    >
      <el-form>
        <el-form-item label="有效期限">
          <el-radio-group v-model="shareFrom.radio">
            <el-radio :label="7">7天</el-radio>
            <el-radio :label="30">30天</el-radio>
            <el-radio :label="0">永久</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="content">
        <p>点击复制链接，发送好友~</p>
        <el-button class="form-operation-item" round type="primary" @click="copyPath">复制链接</el-button>
      </div>
    </el-dialog>
    <!--图片上传-->
    <ali-upload :dialogShow="dialogShow" :accept_type="acceptType" :limit="500" @uploadresult="uploadresult" @closeDialog="closeDialog"></ali-upload>
    <!-- 视频弹框-->
    <el-dialog class="dzdialog" :title="videoTitle" :visible.sync="isvideoDialogVisible" top="20vh" width="840px" v-if="isvideoDialogVisible" :before-close="handleClose">
      <play-video :file_id="media_id" class="play-video"></play-video>
    </el-dialog>
  </div>
</template>
<script>
import aliUpload from 'components/AliUpload.vue';
import PlayVideo from 'components/playVideo';
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    acceptType: {
      type: String,
      default: '',
    },
  },
  data() {
    var checkName = (rule, value, callback) => {
      let check = /[\\ \/=]/g;
      if (value === '') {
        return callback(new Error('请输入名称'));
      } else if (check.test(value)) {
        callback();
        // return callback(new Error('不能输入特殊字符'));
      } else {
        callback();
      }
    };
    return {
      isvideoDialogVisible: '',
      videoTitle: '',
      media_id: '',
      shareFrom: {
        radio: 7,
      },
      dialogShow: false,
      isdelDialogVisible: false,
      dialogShare: false,
      dialogFormVisible: false,
      form: {
        name: '',
        change_time: '',
        options: 'name',
      },
      nameList: [
        {
          value: 'name',
          label: '名称',
        },
        {
          value: 'nickname',
          label: '昵称',
        },
      ],
      start_date: '',
      end_date: '',
      loading: false,
      multipleSelection: [],
      page: 1,
      pageSize: 10,
      total: 0,
      listData: [],
      editForm: {
        name: '',
      },
      rules: {
        name: [{ required: true, validator: checkName, trigger: 'blur' }],
      },
      isAll: false,
      file_ids: [],
      user_id: '',
      circle_id: '',
      ids: [],
      parent_id: 0,
      is_admin_middleground: '',
    };
  },
  computed: {
    fileName() {
      switch (this.type) {
        case 'image':
          return '照片';
        case 'video':
          return '视频';
        case 'info':
          return '文稿';
        default:
          break;
      }
    },
  },
  created() {
    this.is_admin_middleground = localStorage.getItem('is_admin_middleground');
  },
  mounted() {
    this.user_id = localStorage.getItem('user_id');
    this.circle_id = localStorage.getItem('circle_id');
    if (this.circle_id) {
      this.getCataloguesList();
    }
  },
  methods: {
    //视频弹框
    videoPlay(val) {
      console.log(val);
      if (val.file_model_type === 'video') {
        this.videoTitle = val.name;
        this.media_id = val.content.MediaUrl;
        this.isvideoDialogVisible = true;
      }
    },
    //视频弹窗关闭
    handleClose() {
      this.isvideoDialogVisible = false;
    },
    choseOptions(val) {
      this.form.options = val;
    },
    // 图片预览
    srcList(row) {
      let arr = [];
      arr.push(row.path);
      return arr;
    },
    closePicDialog() {
      this.dialogShow = false;
    },
    // 复制链接
    copyPath() {
      let host = window.location.host;
      let protocol = window.location.protocol;
      let timeNum = 86400000;
      let dayTime = new Date().getTime();
      let url = '';
      if (this.is_admin_middleground) {
        url = `${protocol}//${host}/sharefiles?id=${this.id}&type=${this.type}&user_id=${this.user_id}&circle_id=${this.circle_id}&is_admin=1`;
      } else {
        url = `${protocol}//${host}/sharefiles?id=${this.id}&type=${this.type}&user_id=${this.user_id}&circle_id=${this.circle_id}&is_admin=0`;
      }
      let path = this.$api.conversion;
      let data = {
        id: this.id,
        type: this.type,
        url,
        expiration_time: this.shareFrom.radio * timeNum,
        expiration_time_key: dayTime,
      };
      this.$http.post(path, data, true).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          const save = function (e) {
            e.clipboardData.setData('text/plain', res.data.data);
            e.preventDefault(); // 阻止默认行为
          };
          document.addEventListener('copy', save); // 添加一个copy事件
          document.execCommand('copy'); // 执行copy方法
          this.$message({ message: '复制成功', type: 'success' });
          setTimeout(() => {
            this.dialogShare = false;
          }, 1000);
        }
      });
    },
    // 批量删除
    allDel() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: '请先选择文件',
          type: 'warning',
        });
      } else {
        this.multipleSelection.forEach((e) => {
          this.file_ids.push(e.id);
        });
        this.isAll = true;
        this.isdelDialogVisible = true;
      }
    },
    // 批量下载
    allDown() {
      // if (this.key !== '') {
      //   this.$message({
      //     message: '请等待上一个文件下载完毕',
      //     type: 'warning',
      //   });
      //   return;
      // }
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: `请先选择文件`,
          type: 'warning',
        });
      } else {
        let file_ids = [];
        let downUrl = this.$api.batchCatalogues;
        let down_key = new Date().getTime();
        // if (this.multipleSelection.length === 1) {
        //   let file = this.multipleSelection[0];
        //   let numUrl = `${this.$api.catalogues}/${file.id}/${this.$api.download}`;
        //   if (this.is_admin_middleground === 'true') {
        //     parmas = {
        //       type: file.is_catalogue === 1 ? 'catalogue' : file.file_model_type,
        //       circle_id: this.circle_id,
        //     };
        //   } else {
        //     parmas = {
        //       type: file.is_catalogue === 1 ? 'catalogue' : file.file_model_type,
        //       circle_id: this.circle_id,
        //       user_id: this.user_id,
        //     };
        //   }
        //   this.$http.post(numUrl, parmas, true).then((res) => {
        //     if (res.data.success) {
        //       this.getCataloguesList();
        //       let name = file.name;
        //       let title = '';
        //       let url = '';
        //       if (this.type === 'video') {
        //         title = `${name}.${file.content.MediaUrl.split('.').pop()}`;
        //         url = file.content.MediaUrl;
        //         console.log(title);
        //       } else {
        //         title = `${name}.${file.path.split('.').pop()}`;
        //         url = file.path;
        //       }
        //       this.$util.downloadRes(url, title);
        //       this.$message({
        //         message: '下载中.......',
        //         type: 'success',
        //       });
        //     } else {
        //       this.$message({
        //         message: res.data.message,
        //         type: 'warning',
        //       });
        //     }
        //   });
        // } else {
        let numData = {};
        // 多个文件
        this.multipleSelection.forEach((e) => {
          file_ids.push(e.id);
        });
        if (this.type === 'video') {
          numData = {
            video_ids: file_ids,
            is_recycle: '0',
            action: 'download',
            down_key,
          };
        } else if (this.type === 'image') {
          numData = {
            image_ids: file_ids,
            is_recycle: '0',
            action: 'download',
            down_key,
          };
        } else {
          numData = {
            info_ids: file_ids,
            is_recycle: '0',
            action: 'download',
            down_key,
          };
        }

        this.$http.post(downUrl, numData, true).then((res) => {
          if (res.data.success) {
            // this.key = res.data.data.key;
            // this.id = res.data.data.ids;
            // this.polling();
            let title = '';
            let url = '';
            this.multipleSelection.forEach((e) => {
              console.log(e);
              if (e.file_model_type === 'video') {
                title = `${e.name}.${e.content.MediaUrl.split('.').pop()}`;
                url = e.content.MediaUrl;
              } else {
                title = `${e.name}.${e.path.split('.').pop()}`;
                url = e.path;
              }
              this.$util.downloadRes(url, title);
            });
            this.$message({
              message: '下载中.......,请去下载内容中查看下载进度',
              type: 'success',
            });
            this.getCataloguesList();
          } else {
            this.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
        // }
      }
    },
    // 获取列表
    getCataloguesList() {
      this.loading = true;
      let url = '';
      if (this.is_admin_middleground === 'true') {
        url = `${this.$api.catalogues}?page=${this.page}&pageSize=${this.pageSize}&options=${this.form.options}&name=${this.form.name}&start_date=${this.start_date}&end_date=${this.end_date}&parent_id=${this.parent_id}&circle_id=${this.circle_id}&type=${this.type}`;
      } else {
        url = `${this.$api.catalogues}?page=${this.page}&pageSize=${this.pageSize}&name=${this.form.name}&start_date=${this.start_date}&end_date=${this.end_date}&parent_id=${this.parent_id}&user_id=${this.user_id}&circle_id=${this.circle_id}&type=${this.type}`;
      }
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.loading = false;
          this.listData = res.data.data.data;
          this.total = res.data.data.meta.total;
        }
      });
    },
    closeDialog() {
      this.dialogShow = false;
    },
    onConfirm(data) {
      console.log(data, 'ccc');
    },
    openUploadVideo() {
      this.dialogShow = true;
    },
    updata(row) {
      this.id = row.id;
      this.dialogFormVisible = true;
      this.editForm.name = row.name;
      this.dialogFormVisible = true;
    },
    // 关闭弹框
    close(formName) {
      this.editForm.name = '';
      this.dialogFormVisible = false;
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.getCataloguesList();
    },
    // 编辑确认
    preservation(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = `${this.$api.catalogues}/${this.id}`;
          let data = {
            name: this.editForm.name,
            id: this.id,
            type: this.type,
          };
          this.$http.put(url, data, true).then((res) => {
            //console.log(res);
            if (res.data.success) {
              this.$message({
                message: '操作成功',
                type: 'success',
              });
              this.close();
              this.getCataloguesList();
            } else {
              this.$message({
                message: res.data.message,
                type: 'error',
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 删除确认
    putTagStatus() {
      if (this.isAll) {
        let data = {};
        let url = this.$api.batchCatalogues;
        if (this.type === 'video') {
          data = {
            video_ids: this.file_ids,
            is_recycle: '0',
            action: 'del',
          };
        } else if (this.type === 'image') {
          data = {
            image_ids: this.file_ids,
            is_recycle: '0',
            action: 'del',
          };
        } else {
          data = {
            info_ids: this.file_ids,
            is_recycle: '0',
            action: 'del',
          };
        }

        //console.log(data);
        this.$http.post(url, data, true).then((res) => {
          if (res.data.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.getCataloguesList();
            this.closeDelDialog();
          } else {
            this.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
      } else {
        let url = `${this.$api.catalogues}/${this.id}?type=${this.type}&is_recycle=0&action=del`;
        this.$http.del(url, true).then((res) => {
          //console.log(res);
          if (res.data.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.getCataloguesList();
            this.closeDelDialog();
          } else {
            this.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
      }
    },
    // 关闭删除弹框
    closeDelDialog() {
      this.isAll = false;
      this.isdelDialogVisible = false;
      this.file_ids = [];
    },
    searchSubmit() {
      this.page = 1;
      this.getCataloguesList();
    },
    resetFun() {
      this.page = 1;
      this.form.name = '';
      this.form.change_time = '';
      this.form.options = 'name';
      this.start_date = '';
      this.end_date = '';
      this.getCataloguesList();
    },
    // 批量操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //  下拉选择
    handleCommand(command) {
      this.id = command[1].id;
      this.title = command[1].name;
      let numUrl = `${this.$api.catalogues}/${this.id}/${this.$api.download}`;
      if (command[0] === 'share') {
        this.shareFrom.radio = 7;
        this.dialogShare = true;
      } else if (command[0] === 'del') {
        this.isdelDialogVisible = true;
      } else {
        let url = '';
        let name = '';
        let down_key = new Date().getTime();
        if (this.type === 'video') {
          name = `${command[1].name}.${command[1].content.MediaUrl.split('.').pop()}`;
          url = command[1].content.MediaUrl;
        } else {
          name = `${command[1].name}.${command[1].path.split('.').pop()}`;
          url = command[1].path;
        }
        this.$util.downloadRes(url, name);
        let parmas = {};
        if (this.is_admin_middleground === 'true') {
          parmas = {
            type: this.type,
            circle_id: this.circle_id,
            down_key,
          };
        } else {
          parmas = {
            type: this.type,
            circle_id: this.circle_id,
            down_key,
            user_id: this.user_id,
          };
        }
        this.$http.post(numUrl, parmas, true).then((res) => {
          if (res.data.success) {
            this.$message({
              message: '下载中.......',
              type: 'success',
            });
          }
        });
      }
    },
    // 时间选择
    timeChange() {
      if (this.form.change_time === null) {
        this.start_date = '';
        this.end_date = '';
      } else {
        this.start_date = this.$carbon.formatDate(this.form.change_time[0]);
        this.end_date = this.$carbon.formatDate(this.form.change_time[1]);
      }
    },
    // 文件上传结束后回调
    uploadresult(data) {
      let url = '';
      if (this.type === 'video') {
        url = this.$api.uploadVideo;
      } else {
        url = `${this.$api.catalogues}/${this.$api.uploadAttachment}`;
      }

      if (data.length) {
        let params = {
          file: data,
          type: this.type,
        };
        this.$http.post(url, params, true).then((res) => {
          if (res.data.success) {
            this.searchSubmit();
          }
        });
      }
      //console.log(data, '这是结果')
    },
  },
  components: { aliUpload, PlayVideo },
};
</script>
<style lang="less" scoped>
@import '~assets/less/files/info.less';
</style>
