<template>
  <type-list :type="type" :acceptType="acceptType"></type-list>
</template>
<script>
import typeList from './TypeList.vue';
export default {
  props: {},
  data() {
    return {
      acceptType: 'image/png, image/jpeg, image/jpg',
      type: 'image',
    };
  },
  created() {},
  mounted() {},
  methods: {},
  components: { typeList },
};
</script>
<style lang="scss" scoped></style>
