<template>
  <div class="">
    <el-dialog title="上传文件" width="540px" class="video-dialog-box" :visible.sync="fileUploadDialog" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <el-upload
        class="upload-video-btn"
        action=""
        :limit="limit_num ? limit_num : 5"
        :auto-upload="false"
        :on-change="handleChange"
        :on-remove="removeVideo"
        :on-exceed="exceedVideo"
        :file-list="fileList"
        v-if="tableList.length > 0 && upload_sub_status"
        :accept="accept_type"
        multiple
      >
        <el-button size="medium" style="background: rgba(255, 165, 9, 1); border: 1px solid rgba(255, 165, 9, 1)" type="primary">添加文件</el-button>
      </el-upload>
      <div class="num_div" v-if="tableList.length > 0">
        <span class="num"
          >总数量 <span>{{ total }}</span> 个</span
        >
        <span class="num"
          >上传成功 <span>{{ total - tableList.length }}</span> 个</span
        >
        <span class="num"
          >剩余 <span>{{ tableList.length }}</span> 个</span
        >
      </div>
      <el-table class="video-table" :data="tableList" style="width: 100%" v-if="tableList.length > 0">
        <el-table-column label="文件名称">
          <template slot-scope="scope">
            <div class="white-space">{{ scope.row.raw ? scope.row.raw.name : '' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="格式" width="100">
          <template slot-scope="scope"
            ><span>{{ scope.row.raw ? scope.row.raw.type : '' }}</span></template
          >
        </el-table-column>
        <el-table-column width="100" label="大小">
          <template slot-scope="scope">
            <span>
              {{ scope.row.raw ? (scope.row.raw.size / 1024 / 1024).toFixed(2) + '.MB' : '' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="60" label="进度">
          <template slot-scope="scope">
            <span> {{ scope.row.progress && scope.row.progress > 0 ? scope.row.progress : 0 }}% </span>
          </template>
        </el-table-column>
        <el-table-column width="60" label="操作">
          <template slot-scope="scope">
            <span @click="tabDel(scope.$index)" class="tab-del red-font-color">删除</span>
          </template>
        </el-table-column>
      </el-table>

      <el-upload
        class="upload-video-box"
        drag
        action=""
        :limit="limit_num ? limit_num : 5"
        :auto-upload="false"
        :on-change="handleChange"
        :on-remove="removeVideo"
        :on-exceed="exceedVideo"
        :file-list="fileList"
        v-if="tableList.length === 0"
        :accept="accept_type"
        :before-upload="beforeUpload"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传{{ accept_type }}文件，一次最多上传{{ limit_num ? limit_num : 5 }}个</div>
      </el-upload>
      <!-- <div class="progress-box">
            <div class="sub-btn" v-if="fileList.length > 0 && upload_sub_status">
              <el-button type="primary" size="medium" @click="fileSubmit">开始上传</el-button>
            </div>
          </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="cancelUpload">关 闭</el-button>
        <el-button type="primary" v-if="fileList.length > 0 && upload_sub_status" size="medium" @click="fileSubmit">开始上传</el-button>
        <!-- <el-button size="medium" @click="cancelUploadVideo" v-if="!upload_sub_status">暂 停</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import _ from 'lodash';
import OSS from 'ali-oss';
export default {
  name: 'uploadFile',
  props: [
    'dialogShow',
    'upload_api_url',
    'folder',
    'create_time',
    'upload_name',
    'file_name_time',
    'multiple',
    'limit',
    'scene_type',
    'default_value',
    'file_info_data',
    'upload_type',
    'accept_type',
  ],
  data() {
    return {
      fileUploadDialog: false,
      fileList: [], // 上传列表
      upload_url: [],
      srcList: [],
      aliyunOssToken: {},
      create_time_status: true,
      file_info: [], // 文件信息
      file_name_time_status: true, //是都文件名家时间戳
      limit_num: 1,
      upload_status: false,
      upload_scene: 'upload', // 场景显示是上传还是编辑器
      activeName: 'upload',
      record_api_url: '', //历史记录上传
      recordData: [], // 历史记录数据
      page: 1,
      recordInfo: {},
      upload_default_type: 'img',
      ossClient: null, // 阿里对象
      upload_sub_status: true,
      file_data: [], // 上传结束后发送的
      tableList: [],
      uploadList: [],
      total: 0,
    };
  },

  created() {
    // 是否开启时间创建
    if (this.create_time) {
      this.create_time_status = this.create_time;
    }
    if (this.file_name_time) {
      this.file_name_time_status = this.file_name_time;
    }
    if (this.limit) {
      this.limit_num = this.limit;
    }
    if (this.scene_type) {
      this.upload_scene = this.scene_type;
    }
    if (this.default_value && this.default_value.length > 0 && this.default_value[0] != '' && this.default_value[0] != undefined) {
      this.upload_url = this.default_value;
      if (this.file_info_data) {
        this.file_info = this.file_info_data;
      }
    }

    // 判断是图片还是文件 upload_type
    if (this.upload_type) {
      this.upload_default_type = this.upload_type;
    }
  },
  watch: {
    default_value() {
      if (this.default_value.length > 0 && this.default_value[0] != '') {
        this.upload_url = this.default_value;
      }
    },
    dialogShow() {
      this.fileUploadDialog = this.dialogShow;
    },
  },

  mounted() {
    this.getOssToken();
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  methods: {
    beforeUpload() {},
    // 上传按钮
    uploadDialog() {
      this.upload_url = [];
      this.file_info = [];
      this.fileUploadDialog = true;
    },
    // 关闭上传弹框
    closeDialog() {
      if (this.upload_status) {
        this.$message.warning('正在上传中，请勿关闭');
        return false;
      }
      this.$emit('closeDialog');
      this.fileUploadDialog = false;
      this.fileList = [];
      this.upload_url = [];
      this.file_info = [];
    },
    // 选择文件
    handleChange(file, fileList) {
      this.tableList = fileList;
      this.total = this.tableList.length;
      var file_type = file.name.substring(file.name.lastIndexOf('.') + 1);
      this.formatLimitFun(file_type, fileList, file);
    },
    // 移除文件时
    removeVideo(file, fileList) {
      this.fileList = fileList;
    },
    // 当选择超出时
    exceedVideo(files, fileList) {
      this.$message({
        message: '超出最大上传个数',
        type: 'warning',
      });
      var file_type = files[0].name.substring(files[0].name.lastIndexOf('.') + 1);
      files[0].status = 'ready';
      let _this = this;
      let current_files = files[0];
      current_files.raw = files[0];
    },

    // 开始上传
    fileSubmit: _.debounce(function () {
      let _this = this;
      this.file_data = [];
      this.ossClient = new OSS({
        accessKeyId: _this.aliyunOssToken.access_id,
        accessKeySecret: _this.aliyunOssToken.access_key,
        region: _this.aliyunOssToken.region,
        bucket: _this.aliyunOssToken.bucket, //_this.aliyunOssToken.bucket
        secure: true,
      });
      _this.upload_sub_status = false;
      _this.forUploadList();
    }, 500),

    // 循环上传
    forUploadList() {
      if (this.fileList.length > 5) {
        this.uploadList = this.fileList.splice(0, 5);
        this.tableList = this.uploadList.concat(this.fileList);
      } else {
        this.uploadList = this.fileList.splice(0, this.fileList.length);
        this.tableList = this.uploadList;
      }
      for (let i = 0; i < this.uploadList.length; i++) {
        this.UploadFun(this.uploadList[i], i);
      }
    },

    // 阿里云上传方法
    UploadFun(file) {
      let _this = this;
      let file_name = this.aliyunOssToken.dir + `/${this.$carbon.getTime()}/${this.$util.randomString(40)}-${file.name}`;
      this.ossClient
        .multipartUpload(file_name, file.raw, {
          progress: (p, cpt, res) => {
            _this.$set(file, 'progress', parseInt(p * 100));
          },
        })
        .then(function (result) {
          _this.file_data.push({
            name: file.name.split('.')[0],
            size: file.size,
            path: result.res.requestUrls[0],
            ext: file.name.split('.')[1],
          });
          file.status = 'success';
          for (let i = 0; i < _this.tableList.length; i++) {
            if (_this.tableList[i].status === 'success') {
              _this.tableList.splice(i, 1);
            }
          }
          if (_this.fileList.length > 0) {
            let shiftList = _this.fileList.shift();
            _this.uploadList.push(shiftList);
            _this.UploadFun(shiftList);
          }
          if (_this.tableList.length == 0) {
            _this.$emit('uploadresult', _this.file_data, file);
            _this.$emit('closeDialog');
            _this.fileUploadDialog = false;
            _this.upload_sub_status = true;
            _this.total = 0;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 暂停上传
    cancelUploadVideo() {
      // this.ossClient.
    },

    // 获取上传通行证
    getOssToken() {
      var _self = this;
      axios
        .get(_self.upload_api_url ? _self.upload_api_url : 'oss/sign', {
          headers: { 'circle-uuid': localStorage.getItem('circle-uuid') },
          params: {
            is_my_file: 1,
            circle_id: localStorage.getItem('circle_id'),
            user_id: localStorage.getItem('user_id'),
          },
        })
        .then((res) => {
          var data = res.data;
          if (data.success) {
            _self.aliyunOssToken = data.data;
          } else {
            _self.$message.warning(data.msg);
          }
        });
    },
    // 格式限制方法
    formatLimitFun(file_type, fileList, file) {
      this.fileList = fileList;
    },
    // 关闭上传弹框
    cancelUpload: _.debounce(function () {
      if (!this.upload_sub_status) {
        this.$message.warning('正在上传中，请不要关闭');
        return false;
      }
      this.$emit('closeDialog');
      this.fileUploadDialog = false;
      this.fileList = [];
      this.tableList = [];
      this.uploadList = [];
      this.total = 0;
    }, 500),
    // 表格删除列表
    tabDel: _.debounce(function (index) {
      if (!this.upload_sub_status) {
        this.$message.warning('正在上传中，请不要关闭');
        return false;
      } else {
        this.fileList.splice(index, 1);
        this.total = this.fileList.length;
      }
    }, 500),
  },
};
</script>

<style scoped>
.upload-video-box >>> .el-upload {
  width: 100%;
  /* margin: 20 auto !important; */
  display: block;
}
.upload-video-box >>> .el-upload .el-upload-dragger {
  width: 100%;
}
.upload-video-box >>> .el-upload__tip {
  width: 100%;
  margin: 12px auto;
}
.upload-video-box >>> .el-upload-list {
  width: 100%;
  margin: 0 auto;
}
.progress-box {
  width: 100%;
  margin: 0 auto;
}
.sub-btn {
  margin-top: 12px;
}
.num_div {
  margin-bottom: 20px;
}
.num {
  margin-right: 10px;
}
.num >>> span {
  color: #81d8d0;
}
.video-table {
  height: 400px;
  overflow: auto;
  margin-top: 0 !important;
}
.el-table >>> .el-table__empty-block {
  display: none;
}
.upload-video-btn {
  margin-bottom: 20px;
}
.upload-video-btn >>> .el-upload-list {
  display: none;
}
.upload-video-box >>> .el-upload-list {
  display: none;
}
.tab-del {
  cursor: pointer;
}
.xz {
  width: 80px;
  height: 36px;
  background: #ffa509;
  border-radius: 6px;
  border: unset;
}
.video-dialog-box {
  font-weight: 400;
}
.video-dialog-box >>> .el-dialog {
  border-radius: 10px;
}
.video-dialog-box >>> .el-dialog .el-dialog__header {
  padding: 15px 20px;
  font-weight: 400;
}
.video-dialog-box >>> .el-dialog .el-dialog__header .el-dialog__title {
  font-size: 16px;
  font-weight: bold;
  color: #1c1f21;
  line-height: 1;
  display: block;
}
.video-dialog-box >>> .el-dialog .el-dialog__header:after {
  content: '';
  display: block;
  position: relative;
  width: 100%;
  height: 1px;
  background: #ebeff2;
  bottom: -20px;
}
.video-table >>> thead tr th {
  font-weight: 400;
  padding: 5px 0;
}
</style>

<style>
.upload-dialog .el-dialog__header {
  display: none;
}
</style>
