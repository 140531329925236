<template>
  <div class="files_contents">
    <el-form :model="form" ref="coursesForm" label-position="right" label-width="150px" class="addForm">
      <div class="" v-if="is_admin_middleground === 'true'">
        <el-select v-model="form.options" class="search-type search-type_first" @change="choseOptions">
          <el-option v-for="item in nameList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-input v-model="form.name" placeholder="请输入查询条件" class="search-input"></el-input>
      </div>
      <div class="" v-else>
        <span class="type_text">名称</span>
        <el-input v-model="form.name" placeholder="请输入查询条件" class="search-input"></el-input>
      </div>
      <div class="">
        <span class="type_text">删除时间</span>
        <el-date-picker
          class="vertical-end"
          style="width: 269px"
          @change="timeChange"
          v-model="form.change_time"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="tool_btn">
        <el-button type="primary" class="search-btn confirm" @click="searchSubmit" style="margin-left: 20px">查询</el-button>
        <el-button class="search-btn reset" @click="resetFun">重置</el-button>
      </div>
    </el-form>
    <div class="total">
      <div class="left">回收站</div>
      <div class="right">
        <el-button type="text" class="total-right vertical-center" @click="allRec">
          <img src="~assets/images/index/recovery.png" alt="" style="vertical-align: bottom" class="del" />
          <img src="~assets/images/index/recovery_hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
          <span style="margin-left: 8px">批量恢复</span>
        </el-button>
        <el-button type="text" class="total-right vertical-center" @click="allDel">
          <img src="~assets/images/index/del.png" alt="" style="vertical-align: bottom" class="del" />
          <img src="~assets/images/index/del-hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
          <span style="margin-left: 8px">批量删除</span>
        </el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      class="table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400' }"
      border
    >
      <el-table-column :selectable="selectEnable" type="selection" label="全选" width="90" align="center"> </el-table-column>
      <el-table-column label="名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div class="el-name file_name">
            <img src="~assets/images/index/files.png" alt="" v-if="scope.row.is_catalogue === 1" />
            <img src="~assets/images/index/play_video.png" alt="" v-if="scope.row.file_model_type === 'video'" />
            <el-image v-if="scope.row.file_model_type === 'image'" :src="scope.row.path" :preview-src-list="srcList(scope.row)"> </el-image>
            <img src="~assets/images/index/word.png" alt="" v-if="scope.row.file_model_type === 'info'" />
            <p>{{ scope.row.name }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="大小" width="90px">
        <template slot-scope="scope">
          <div class="el-name">
            {{
              scope.row ? ((scope.row.size / 1024 / 1024).toFixed(2) > 1000 ? (scope.row.size / 1024 / 1024 / 1024).toFixed(2) + 'GB' : (scope.row.size / 1024 / 1024).toFixed(2) + 'MB') : ''
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建人" width="150px">
        <template slot-scope="scope">
          <div class="el-name el-nickname">{{ scope.row.user ? scope.row.user.nickname : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="删除时间" width="170px">
        <template slot-scope="scope">
          <div class="el-name">{{ scope.row.updated_at }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="110">
        <template slot-scope="scope">
          <el-button type="text" size="small" class="table-edit" @click="recovery(scope.row)">恢复</el-button>
          <span style="color: #d0d6de; margin: 0 8px">|</span>
          <el-button type="text" size="small" class="table-edit" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="page"
      background
      v-if="listData.length !== 0 && total > 10"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[20, 50, 100]"
      :page-size="pageSize"
      layout="prev, pager, next,total, jumper"
      :total="total"
    >
    </el-pagination>
    <!--    删除弹框-->
    <el-dialog
      class="delDiolog"
      :close-on-click-modal="false"
      :visible.sync="isdelDialogVisible"
      top="calc( 50vh - 150px )"
      icon="el-icon-warning"
      :destroy-on-close="true"
      width="360px"
      center
      @close="closeDelDialog"
    >
      <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
      <span>你确定要删除该数据么？</span>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="closeDelDialog">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="putTagStatus">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      isdelDialogVisible: false,
      form: {
        name: '',
        change_time: '',
        options: 'name',
      },
      nameList: [
        {
          value: 'name',
          label: '名称',
        },
        {
          value: 'nickname',
          label: '昵称',
        },
      ],
      start_date: '',
      end_date: '',
      loading: false,
      multipleSelection: [],
      page: 1,
      pageSize: 10,
      total: 0,
      listData: [],

      type: '',
      isAll: false,
      catalogue_ids: [],
      video_ids: [],
      image_ids: [],
      info_ids: [],
      user_id: '',
      circle_id: '',
      is_admin_middleground: '',
    };
  },
  created() {},

  mounted() {
    this.is_admin_middleground = localStorage.getItem('is_admin_middleground');
    this.user_id = localStorage.getItem('user_id');
    this.circle_id = localStorage.getItem('circle_id');
    if (this.circle_id) {
      this.getCataloguesList();
    }
  },
  methods: {
    selectEnable(row) {
      if (row.is_catalogue === 1) {
        return false;
      } else {
        return true; // 不禁用
      }
    },

    choseOptions(val) {
      this.form.options = val;
    },
    // 图片预览
    srcList(row) {
      let arr = [];
      arr.push(row.path);
      return arr;
    },
    // 批量恢复
    allRec() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: '请先选择文件或视频',
          type: 'warning',
        });
      } else {
        let catalogue = [];
        let videoList = [];
        let imageList = [];
        let infoList = [];
        catalogue = this.multipleSelection.filter((e) => {
          return e.is_catalogue === 1;
        });
        catalogue.forEach((e) => {
          this.catalogue_ids.push(e.id);
        });
        videoList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'video';
        });
        imageList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'image';
        });
        infoList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'info';
        });
        videoList.forEach((e) => {
          this.video_ids.push(e.id);
        });
        imageList.forEach((e) => {
          this.image_ids.push(e.id);
        });
        infoList.forEach((e) => {
          this.info_ids.push(e.id);
        });
        let url = this.$api.batchCatalogues;
        let data = {
          video_ids: this.video_ids.length > 0 ? this.video_ids : '',
          image_ids: this.image_ids.length > 0 ? this.image_ids : '',
          info_ids: this.info_ids.length > 0 ? this.info_ids : '',
          catalogue_ids: this.catalogue_ids.length > 0 ? this.catalogue_ids : '',
          is_recycle: '1',
          action: 'rec',
        };
        this.$http.post(url, data, true).then((res) => {
          if (res.data.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.getCataloguesList();
            this.catalogue_ids = [];
            this.video_ids = [];
            this.image_ids = [];
            this.info_ids = [];
          } else {
            this.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
      }
    },
    // 批量删除
    allDel() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: '请先选择文件或视频',
          type: 'warning',
        });
      } else {
        let catalogue = [];
        let videoList = [];
        let imageList = [];
        let infoList = [];
        catalogue = this.multipleSelection.filter((e) => {
          return e.is_catalogue === 1;
        });
        catalogue.forEach((e) => {
          this.catalogue_ids.push(e.id);
        });
        videoList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'video';
        });
        imageList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'image';
        });
        infoList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'info';
        });
        videoList.forEach((e) => {
          this.video_ids.push(e.id);
        });
        imageList.forEach((e) => {
          this.image_ids.push(e.id);
        });
        infoList.forEach((e) => {
          this.info_ids.push(e.id);
        });
        this.isAll = true;
        this.isdelDialogVisible = true;
      }
    },
    // 恢复
    recovery(row) {
      if (row.is_catalogue === 1) {
        this.type = 'catalogue';
      } else {
        this.type = row.file_model_type;
      }
      this.id = row.id;
      let url = `${this.$api.catalogues}/${this.id}?type=${this.type}&action=rec&is_recycle=1`;
      this.$http.del(url, true).then((res) => {
        if (res.data.success) {
          this.$message({
            message: '操作成功',
            type: 'success',
          });
          this.getCataloguesList();
          // this.closeDelDialog();
        }
      });
    },
    del(row) {
      if (row.is_catalogue === 1) {
        this.type = 'catalogue';
      } else {
        this.type = row.file_model_type;
      }
      this.id = row.id;
      this.isdelDialogVisible = true;
    },
    // 关闭删除弹框
    closeDelDialog() {
      this.isAll = false;
      this.isdelDialogVisible = false;
      this.catalogue_ids = [];
      this.video_ids = [];
      this.image_ids = [];
      this.info_ids = [];
    },
    // 获取列表
    getCataloguesList() {
      this.loading = true;
      let url = '';
      if (this.is_admin_middleground === 'true') {
        url = `${this.$api.catalogues}?page=${this.page}&pageSize=${this.pageSize}&options=${this.form.options}&name=${this.form.name}&start_date=${this.start_date}&end_date=${this.end_date}&is_recycle=1&circle_id=${this.circle_id}`;
      } else {
        url = `${this.$api.catalogues}?page=${this.page}&pageSize=${this.pageSize}&name=${this.form.name}&start_date=${this.start_date}&end_date=${this.end_date}&is_recycle=1&user_id=${this.user_id}&circle_id=${this.circle_id}`;
      }
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.listData = res.data.data.data;
          this.total = res.data.data.meta.total;
        }
      });
    },
    // 删除确认
    putTagStatus() {
      if (this.isAll) {
        let url = this.$api.batchCatalogues;
        let data = {
          video_ids: this.video_ids.length > 0 ? this.video_ids : '',
          image_ids: this.image_ids.length > 0 ? this.image_ids : '',
          info_ids: this.info_ids.length > 0 ? this.info_ids : '',
          catalogue_ids: this.catalogue_ids.length > 0 ? this.catalogue_ids : '',
          is_recycle: '1',
          action: 'del',
        };
        this.$http.post(url, data, true).then((res) => {
          if (res.data.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.getCataloguesList();
            this.closeDelDialog();
          } else {
            this.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
      } else {
        let url = `${this.$api.catalogues}/${this.id}?type=${this.type}&action=del&is_recycle=1`;
        this.$http.del(url, true).then((res) => {
          if (res.data.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.getCataloguesList();
            this.closeDelDialog();
          }
        });
      }
    },
    searchSubmit() {
      this.page = 1;
      this.getCataloguesList();
    },
    resetFun() {
      this.form.name = '';
      this.form.change_time = '';
      this.start_date = '';
      this.end_date = '';
      this.getCataloguesList();
    },
    // 批量操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.getCataloguesList();
    },

    // 时间选择
    timeChange() {
      if (this.form.change_time === null) {
        this.start_date = '';
        this.end_date = '';
      } else {
        this.start_date = this.$carbon.formatDate(this.form.change_time[0]);
        this.end_date = this.$carbon.formatDate(this.form.change_time[1]);
      }
    },
  },
  components: {},
};
</script>
<style lang="less" scoped>
@import '~assets/less/files/info.less';
</style>
