<template>
  <type-list :type="type" :acceptType="acceptType"></type-list>
</template>
<script>
import typeList from './TypeList.vue';
export default {
  props: {},
  data() {
    return {
      acceptType: '.mp4,.avi,.m4v,.mov',
      type: 'video',
    };
  },
  created() {},
  mounted() {},
  methods: {},
  components: { typeList },
};
</script>
<style lang="scss" scoped></style>
