<template>
  <div class="rule">
    <el-tabs v-model="activeName" class="el-nav" @tab-click="tabClick">
      <el-tab-pane :label="allTitle" name="all">
        <AllFile v-if="activeName === 'all'"></AllFile>
      </el-tab-pane>
      <el-tab-pane v-for="item in tabPane" :label="item.label" :key="item.name" :name="item.name">
        <div v-if="item.isShow">
          <component :is="item.content"></component>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import AllFile from '../components/AllFile.vue';
import MyVideo from '../components/MyVideo.vue';
import MyPic from '../components/MyPic.vue';
import MyText from '../components/MyText.vue';
import Recycle from '../components/Recycle.vue';
export default {
  data() {
    return {
      activeName: 'all',
      is_admin_middleground: '',
      allTitle: '全部文件',
      tabPane: [
        {
          label: '照片',
          name: 'pic',
          content: MyPic,
          isShow: false,
        },
        {
          label: '视频',
          name: 'video',
          content: MyVideo,
          isShow: false,
        },
        {
          label: '文稿',
          name: 'text',
          content: MyText,
          isShow: false,
        },
        {
          label: '回收站',
          name: 'recycle',
          content: Recycle,
          isShow: false,
        },
      ],
    };
  },
  created() {
    this.is_admin_middleground = localStorage.getItem('is_admin_middleground');
    if (this.is_admin_middleground === 'true') {
      this.allTitle = '全部文件';
    } else {
      this.allTitle = '我的文件';
    }
  },
  mounted() {},
  methods: {
    tabClick(val) {
      this.tabPane.forEach((e) => {
        if (e.name === val.name) {
          e.isShow = true;
        } else {
          e.isShow = false;
        }
      });
    },
  },
  components: { AllFile, MyPic, MyVideo, MyText, Recycle },
};
</script>
<style lang="less" scoped>
@import '~assets/less/files/index.less';
</style>
