<template>
  <div class="files_contents">
    <el-form :model="form" ref="coursesForm" label-position="right" label-width="150px" class="addForm">
      <div class="" v-if="is_admin_middleground === 'true'">
        <el-select v-model="form.options" class="search-type search-type_first" @change="choseOptions">
          <el-option v-for="item in nameList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-input v-model="form.name" placeholder="请输入查询条件" class="search-input"></el-input>
      </div>
      <div class="" v-else>
        <span class="type_text">名称</span>
        <el-input v-model="form.name" placeholder="请输入查询条件" class="search-input"></el-input>
      </div>
      <div class="">
        <span class="type_text">创建时间</span>
        <el-date-picker
          class="vertical-end"
          style="width: 248px"
          @change="timeChange"
          v-model="form.change_time"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="tool_btn">
        <el-button type="primary" class="search-btn confirm" @click="searchSubmit" style="margin-left: 20px">查询</el-button>
        <el-button class="search-btn reset" @click="resetFun">重置</el-button>
      </div>
      <div class="yellow_btn">
        <el-dropdown @command="open($event)" class="f-r search-add" trigger="click">
          <el-button type="primary" class="search-add"> 上传文件<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="pic"><div class="" style="width: 59px; text-align: center">照片</div></el-dropdown-item>
            <el-dropdown-item command="video"><div class="" style="width: 59px; text-align: center">视频</div></el-dropdown-item>
            <el-dropdown-item command="txt"><div class="" style="width: 59px; text-align: center">文稿</div></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button class="yellow sec" @click="addFile">新增文件夹</el-button>
      </div>
    </el-form>
    <div class="total">
      <div class="left">
        <div class="bread" v-for="(item, index) in breadCrumbList" :key="index">
          <span class="title" :class="index + 1 !== breadCrumbList.length ? 'green' : ''" @click="breadInfo(item, index)"> {{ item.name }}</span>
          <span class="line" v-if="index + 1 !== breadCrumbList.length">/</span>
        </div>
      </div>
      <div class="right">
        <el-button type="text" class="total-right vertical-center" @click="allDel">
          <img src="~assets/images/index/del.png" alt="" style="vertical-align: bottom" class="del" />
          <img src="~assets/images/index/del-hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
          <span style="margin-left: 8px">批量删除</span>
        </el-button>
        <el-button type="text" class="total-right vertical-center" @click="allDown">
          <img src="~assets/images/index/down_blue.png" alt="" style="vertical-align: bottom" class="del" />
          <img src="~assets/images/index/down_hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
          <span style="margin-left: 8px">批量下载</span>
        </el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      class="table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400' }"
      border
    >
      <el-table-column :selectable="selectEnable" type="selection" label="全选" width="90" align="center"> </el-table-column>
      <el-table-column label="名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div class="el-name file_name" @click="videoPlay(scope.row)">
            <img src="~assets/images/index/files.png" alt="" v-if="scope.row.is_catalogue === 1" />
            <img src="~assets/images/index/play_video.png" alt="" v-if="scope.row.file_model_type === 'video'" />
            <el-image v-if="scope.row.file_model_type === 'image'" :src="scope.row.path" :preview-src-list="srcList(scope.row)"> </el-image>
            <img src="~assets/images/index/word.png" alt="" v-if="scope.row.file_model_type === 'info'" />
            <p @click="breadCrumb(scope.row)">{{ scope.row.name }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="大小" width="90px">
        <template slot-scope="scope">
          <div class="el-name">
            {{
              scope.row ? ((scope.row.size / 1024 / 1024).toFixed(2) > 1000 ? (scope.row.size / 1024 / 1024 / 1024).toFixed(2) + 'GB' : (scope.row.size / 1024 / 1024).toFixed(2) + 'MB') : ''
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建人" width="150px">
        <template slot-scope="scope">
          <div class="el-name el-nickname">{{ scope.row.user ? scope.row.user.nickname : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="170px">
        <template slot-scope="scope">
          <div class="el-name">{{ scope.row.created_at }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="110">
        <template slot-scope="scope">
          <el-button type="text" size="small" class="table-edit" @click="updata(scope.row)">编辑</el-button>
          <span style="color: #d0d6de; margin: 0 8px">|</span>
          <el-dropdown @command="handleCommand" trigger="click">
            <span class="table-edit table-span"> 更多 </span>
            <el-dropdown-menu slot="dropdown" class="drop">
              <el-dropdown-item :command="['share', scope.row]"><i class="el-icon-check" style="opacity: 0"></i>分享</el-dropdown-item>
              <el-dropdown-item :command="['del', scope.row]"><i class="el-icon-check" style="opacity: 0"></i>删除</el-dropdown-item>
              <el-dropdown-item :command="['down', scope.row]"><i class="el-icon-check" style="opacity: 0"></i>下载</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="page"
      background
      v-if="listData.length !== 0 && total > 10"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[20, 50, 100]"
      :page-size="pageSize"
      layout="prev, pager, next,total, jumper"
      :total="total"
    >
    </el-pagination>
    <!--编辑--->
    <el-dialog
      class="dialog"
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
      top="calc( 50vh - 150px )"
      width="540px"
      :destroy-on-close="true"
      @close="close('from')"
      :close-on-click-modal="false"
    >
      <!--  表单-->
      <el-form label-position="left" label-width="60px" class="form" ref="from" :rules="rules" :model="editForm">
        <el-form-item label="名称" prop="name" class="is-required">
          <el-input v-model.trim="editForm.name" placeholder="请输入名称" maxlength="30"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="close">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="preservation('from')">保存</el-button>
      </span>
    </el-dialog>
    <!--    删除弹框-->
    <el-dialog
      class="delDiolog"
      :close-on-click-modal="false"
      :visible.sync="isdelDialogVisible"
      top="calc( 50vh - 150px )"
      icon="el-icon-warning"
      :destroy-on-close="true"
      width="360px"
      center
      @close="closeDelDialog"
    >
      <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
      <span>你确定要删除该数据么？</span>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="closeDelDialog">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="putTagStatus">确 定</el-button>
      </span>
    </el-dialog>
    <!--分享--->
    <el-dialog
      class="dialog share_dialog"
      :close-on-click-modal="false"
      title="分享"
      :visible.sync="dialogShare"
      v-if="dialogShare"
      top="calc( 50vh - 150px )"
      width="540px"
      :destroy-on-close="true"
    >
      <el-form>
        <el-form-item label="有效期限">
          <el-radio-group v-model="shareFrom.radio">
            <el-radio :label="7">7天</el-radio>
            <el-radio :label="30">30天</el-radio>
            <el-radio :label="0">永久</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="content">
        <p>点击复制链接，发送好友~</p>
        <el-button class="form-operation-item" round type="primary" @click="copyPath">复制链接</el-button>
      </div>
    </el-dialog>
    <!-- 视频弹框-->
    <el-dialog class="dzdialog" :title="videoTitle" :visible.sync="isvideoDialogVisible" top="20vh" width="840px" v-if="isvideoDialogVisible" :before-close="handleClose">
      <play-video :file_id="media_id" class="play-video"></play-video>
    </el-dialog>
    <!-- app弹框-->
    <el-dialog class="appdialog" title="提示" :visible.sync="isappDialogVisible" top="20vh" width="554px" v-if="isappDialogVisible" :before-close="handleAppClose">
      <p>文件夹只能在客户端中下载，是否下载“君莲书院”客户端？</p>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="handleAppClose">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="downApp">立即下载</el-button>
      </span>
    </el-dialog>
    <!--视频上传-->
    <ali-upload :dialogShow="dialogShow" :accept_type="acceptType" :limit="500" @uploadresult="uploadresult" @closeDialog="closeDialog"></ali-upload>
  </div>
</template>
<script>
import aliUpload from 'components/AliUpload.vue';
import PlayVideo from 'components/playVideo';
export default {
  // props: {},

  data() {
    var checkName = (rule, value, callback) => {
      console.log(value, '==================');
      let check = /[\\ \/=]/g;
      if (value === '') {
        return callback(new Error('请输入名称'));
      } else if (check.test(value)) {
        callback();
        // return callback(new Error('不能输入特殊字符'));
      } else {
        callback();
      }
    };
    return {
      versionInfo: '',
      timer: null,
      shareFrom: {
        radio: 7,
      },
      isappDialogVisible: false,
      dialogShow: false,
      isvideoDialogVisible: false,
      dialogTitle: '新增名称',
      acceptType: '',
      breadCrumbList: [
        {
          id: 0,
          name: '全部文件',
        },
      ],
      isdelDialogVisible: false,
      dialogShare: false,
      form: {
        name: '',
        change_time: '',
        options: 'name',
      },
      nameList: [
        {
          value: 'name',
          label: '名称',
        },
        {
          value: 'nickname',
          label: '昵称',
        },
      ],
      editForm: {
        name: '',
      },
      start_date: '',
      end_date: '',
      loading: false,
      multipleSelection: [],
      dialogFormVisible: false,
      page: 1,
      pageSize: 10,
      total: 0,
      parent_id: 0,
      listData: [],
      id: '',
      type: '',
      rules: {
        name: [{ required: true, validator: checkName, trigger: 'blur' }],
      },
      title: '',
      isAll: false,
      catalogue_ids: [],
      video_ids: [],
      image_ids: [],
      info_ids: [],
      ids: [],
      putName: false,
      user_id: '',
      circle_id: '',
      file_url: '',
      videoTitle: '',
      media_id: '',
      file_type: '',
      is_admin_middleground: '',
    };
  },

  created() {
    this.is_admin_middleground = localStorage.getItem('is_admin_middleground');
  },

  mounted() {
    this.user_id = localStorage.getItem('user_id');
    this.circle_id = localStorage.getItem('circle_id');
    if (this.circle_id) {
      this.getCataloguesList();
    }
  },

  methods: {
    downApp() {
      window.location.href = this.versionInfo;
      setTimeout(() => {
        this.handleAppClose();
      }, 300);
    },
    handleAppClose() {
      this.isappDialogVisible = false;
      this.getCataloguesList();
    },
    selectEnable(row) {
      if (row.is_catalogue === 1) {
        return false;
      } else {
        return true; // 不禁用
      }
    },

    choseOptions(val) {
      this.form.options = val;
    },
    // 图片预览
    srcList(row) {
      let arr = [];
      arr.push(row.path);
      return arr;
    },
    //视频弹框
    videoPlay(val) {
      console.log(val);
      if (val.file_model_type === 'video') {
        this.videoTitle = val.name;
        this.media_id = val.content.MediaUrl;
        this.isvideoDialogVisible = true;
      }
    },
    //弹窗关闭
    handleClose() {
      this.isvideoDialogVisible = false;
    },

    // 下拉弹框
    open(val) {
      if (val === 'video') {
        this.file_type = 'video';
        this.acceptType = '.mp4,.m4v,avi,.mov';
      } else if (val === 'pic') {
        this.file_type = 'image';
        this.acceptType = 'image/png, image/jpeg, image/jpg';
      } else {
        this.file_type = 'info';
        this.acceptType = '.ppt,.pptx,.doc,.docx,.pdf,.zip,.xlsx,.txt,.csv,.xls';
      }
      this.dialogShow = true;
    },
    // 复制链接
    copyPath() {
      let host = window.location.host;
      let protocol = window.location.protocol;
      let timeNum = 86400000;
      let dayTime = new Date().getTime();
      let url = '';
      if (this.is_admin_middleground) {
        url = `${protocol}//${host}/sharefiles?id=${this.id}&type=${this.type}&user_id=${this.user_id}&circle_id=${this.circle_id}&is_admin=1`;
      } else {
        url = `${protocol}//${host}/sharefiles?id=${this.id}&type=${this.type}&user_id=${this.user_id}&circle_id=${this.circle_id}&is_admin=0`;
      }

      let path = this.$api.conversion;
      let data = {
        id: this.id,
        type: this.type,
        url,
        expiration_time: this.shareFrom.radio * timeNum,
        expiration_time_key: dayTime,
      };
      this.$http.post(path, data, true).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          const save = function (e) {
            e.clipboardData.setData('text/plain', res.data.data);
            e.preventDefault(); // 阻止默认行为
          };
          document.addEventListener('copy', save); // 添加一个copy事件
          document.execCommand('copy'); // 执行copy方法
          this.$message({ message: '复制成功', type: 'success' });
          setTimeout(() => {
            this.dialogShare = false;
          }, 1000);
        } else {
          this.$message({
            message: res.data.message,
            type: 'error',
          });
        }
      });
    },
    // 批量删除
    allDel() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: '请先选择文件或视频',
          type: 'warning',
        });
      } else {
        let catalogue = [];
        let videoList = [];
        let imageList = [];
        let infoList = [];
        catalogue = this.multipleSelection.filter((e) => {
          return e.is_catalogue === 1;
        });
        catalogue.forEach((e) => {
          this.catalogue_ids.push(e.id);
        });
        videoList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'video';
        });
        imageList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'image';
        });
        infoList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'info';
        });
        videoList.forEach((e) => {
          this.video_ids.push(e.id);
        });
        imageList.forEach((e) => {
          this.image_ids.push(e.id);
        });
        infoList.forEach((e) => {
          this.info_ids.push(e.id);
        });
        this.isAll = true;
        this.isdelDialogVisible = true;
      }
    },

    // 获取app下载地址
    getAppUrl() {
      let versionInfoUrl = this.$api.versionInfo;
      this.$http.get(versionInfoUrl).then((res) => {
        this.versionInfo = res.data.data.apk_url;
      });
    },
    // 批量下载
    allDown() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: '请先选择文件',
          type: 'warning',
        });
      } else {
        let videoList = [];
        let imageList = [];
        let infoList = [];
        let catalogueList = [];
        let catalogue_ids = [];
        let video_ids = [];
        let image_ids = [];
        let info_ids = [];
        let downUrl = this.$api.batchCatalogues;
        let down_key = new Date().getTime();

        // 多个文件
        catalogueList = this.multipleSelection.filter((e) => {
          return e.is_catalogue === 1;
        });
        videoList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'video';
        });
        imageList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'image';
        });
        infoList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'info';
        });

        if (catalogueList.length > 0) {
          this.isappDialogVisible = true;
          this.getAppUrl();
          return;
        }
        videoList.forEach((e) => {
          video_ids.push(e.id);
        });
        imageList.forEach((e) => {
          image_ids.push(e.id);
        });
        infoList.forEach((e) => {
          info_ids.push(e.id);
        });

        let numData = {
          catalogue_ids: catalogue_ids.length > 0 ? catalogue_ids : '',
          video_ids: video_ids.length > 0 ? video_ids : '',
          image_ids: image_ids.length > 0 ? image_ids : '',
          info_ids: info_ids.length > 0 ? info_ids : '',
          is_recycle: '0',
          action: 'download',
          down_key,
        };

        this.$http.post(downUrl, numData, true).then((res) => {
          console.log(res.data);
          if (res.data.success) {
            let title = '';
            let url = '';
            this.multipleSelection.forEach((e) => {
              console.log(e);
              if (e.file_model_type === 'video') {
                title = `${e.name}.${e.content.MediaUrl.split('.').pop()}`;
                url = e.content.MediaUrl;
              } else {
                title = `${e.name}.${e.path.split('.').pop()}`;
                url = e.path;
              }
              this.$util.downloadRes(url, title);
            });
            this.$message({
              message: '下载中.......,请去下载内容中查看下载进度',
              type: 'success',
            });
            this.getCataloguesList();
          } else {
            this.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
        // }
      }
    },
    // 获取列表
    getCataloguesList() {
      this.loading = true;
      let url = '';
      if (this.is_admin_middleground === 'true') {
        url = `${this.$api.catalogues}?page=${this.page}&pageSize=${this.pageSize}&options=${this.form.options}&name=${this.form.name}&start_date=${this.start_date}&end_date=${this.end_date}&parent_id=${this.parent_id}&circle_id=${this.circle_id}`;
      } else {
        url = `${this.$api.catalogues}?page=${this.page}&pageSize=${this.pageSize}&name=${this.form.name}&start_date=${this.start_date}&end_date=${this.end_date}&parent_id=${this.parent_id}&user_id=${this.user_id}&circle_id=${this.circle_id}`;
      }
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.listData = res.data.data.data;
          this.total = res.data.data.meta.total;
        }
      });
    },
    // 关掉视频弹框
    closeDialog() {
      this.file_type = '';
      this.dialogShow = false;
    },

    addFile() {
      this.dialogTitle = '新增文件夹';
      this.dialogFormVisible = true;
    },
    // 面包屑
    breadInfo(item, index) {
      this.breadCrumbList.splice(index + 1);
      this.parent_id = item.id;
      this.page = 1;
      this.getCataloguesList();
    },
    // 选中的标题
    breadCrumb(row) {
      if (row.is_catalogue === 1) {
        this.parent_id = row.id;
        this.getCataloguesList();
        this.breadCrumbList.push(row);
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.getCataloguesList();
    },
    updata(row) {
      this.id = row.id;
      if (row.is_catalogue === 1) {
        this.type = 'catalogue';
      } else {
        this.type = row.file_model_type;
      }
      this.dialogTitle = '编辑名称';
      this.editForm.name = row.name;
      this.dialogFormVisible = true;
      this.putName = true;
    },
    // 关闭弹框
    close() {
      this.editForm.name = '';
      this.dialogFormVisible = false;
      this.putName = false;
    },
    // 编辑确认
    preservation(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.putName) {
            let url = `${this.$api.catalogues}/${this.id}`;
            let data = {
              name: this.editForm.name,
              id: this.id,
              type: this.type,
            };
            this.$http.put(url, data, true).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                });
                this.close();
                this.getCataloguesList();
              }
            });
          } else {
            let url = `${this.$api.catalogues}`;
            let data = {
              name: this.editForm.name,
              parent_id: this.parent_id,
            };
            this.$http
              .post(url, data, true)
              .then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                  });
                  this.close();
                  this.getCataloguesList();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.$message.error(error.response.data.message);
              });
          }
        } else {
          return false;
        }
      });
    },
    // 删除确认
    putTagStatus() {
      console.log(this.type, this.id);
      if (this.isAll) {
        let url = this.$api.batchCatalogues;
        let data = {
          video_ids: this.video_ids.length > 0 ? this.video_ids : '',
          image_ids: this.image_ids.length > 0 ? this.image_ids : '',
          info_ids: this.info_ids.length > 0 ? this.info_ids : '',
          catalogue_ids: this.catalogue_ids.length > 0 ? this.catalogue_ids : '',
          is_recycle: '0',
          action: 'del',
        };
        this.$http.post(url, data, true).then((res) => {
          if (res.data.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.getCataloguesList();
            this.closeDelDialog();
            this.video_ids = [];
            this.image_ids = [];
            this.info_ids = [];
            this.catalogue_ids = [];
          } else {
            this.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
      } else {
        let url = `${this.$api.catalogues}/${this.id}?type=${this.type}&is_recycle=0&action=del`;
        this.$http.del(url, true).then((res) => {
          //console.log(res);
          if (res.data.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.getCataloguesList();
            this.closeDelDialog();
            this.video_ids = [];
            this.image_ids = [];
            this.info_ids = [];
            this.catalogue_ids = [];
          }
        });
      }
    },
    // 关闭删除弹框
    closeDelDialog() {
      this.isAll = false;
      this.isdelDialogVisible = false;
      this.catalogue_ids = [];
      this.video_ids = [];
    },
    // 查询
    searchSubmit() {
      this.page = 1;
      this.getCataloguesList();
    },
    // 重置
    resetFun() {
      this.page = 1;
      this.form.name = '';
      this.form.change_time = '';
      this.form.options = 'name';
      this.start_date = '';
      this.end_date = '';
      this.getCataloguesList();
    },
    // 批量操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //  下拉选择
    handleCommand(command) {
      this.id = command[1].id;
      if (command[1].is_catalogue === 1) {
        this.type = 'catalogue';
      } else {
        this.type = command[1].file_model_type;
      }
      this.title = command[1].name;
      if (command[0] === 'share') {
        this.dialogShare = true;
        this.shareFrom.radio = 7;
      } else if (command[0] === 'del') {
        this.isdelDialogVisible = true;
      } else {
        let down_key = new Date().getTime();
        let numUrl = `${this.$api.catalogues}/${this.id}/${this.$api.download}`;
        let parmas = {};
        if (command[1].is_catalogue === 1) {
          this.isappDialogVisible = true;
          this.getAppUrl();
          return;
        }
        if (this.is_admin_middleground === 'true') {
          parmas = {
            type: this.type,
            circle_id: this.circle_id,
            down_key,
          };
        } else {
          parmas = {
            type: this.type,
            circle_id: this.circle_id,
            down_key,
            user_id: this.user_id,
          };
        }

        this.$http.post(numUrl, parmas, true).then((res) => {
          if (res.data.success) {
            let name = command[1].name;
            let title = '';
            let url = '';
            if (command[1].file_model_type === 'video') {
              title = `${name}.${command[1].content.MediaUrl.split('.').pop()}`;
              url = command[1].content.MediaUrl;
            } else {
              title = `${name}.${command[1].path.split('.').pop()}`;
              url = command[1].path;
            }
            this.$util.downloadRes(url, title);
            this.$message({
              message: '下载中.......,请去下载内容中查看下载进度',
              type: 'success',
            });
          } else {
            this.$message({
              message: res.data.message,
              type: 'warning',
            });
          }
        });
      }
    },
    // 时间选择
    timeChange() {
      if (this.form.change_time === null) {
        this.start_date = '';
        this.end_date = '';
      } else {
        this.start_date = this.$carbon.formatDate(this.form.change_time[0]);
        this.end_date = this.$carbon.formatDate(this.form.change_time[1]);
      }
    },
    // 文件上传结束后回调
    uploadresult(data) {
      if (data.length) {
        let params = {};
        let url = '';
        if (this.file_type === 'video') {
          url = this.$api.uploadVideo;
          params = {
            file: data,
            catalogue_id: this.breadCrumbList[this.breadCrumbList.length - 1].id,
          };
        } else {
          url = `${this.$api.catalogues}/${this.$api.uploadAttachment}`;
          params = {
            file: data,
            catalogue_id: this.breadCrumbList[this.breadCrumbList.length - 1].id,
            type: this.file_type,
          };
        }
        this.$http.post(url, params, true).then((res) => {
          if (res.data.success) {
            this.searchSubmit();
          }
        });
      }
      console.log(data, '这是结果');
    },
  },
  components: {
    aliUpload,
    PlayVideo,
  },
};
</script>
<style lang="less" scoped>
@import '~assets/less/files/info.less';
</style>
